/* Default styles for the code editor */
.code-editor {
    width: 80%;
    max-width: 800px; /* Set a maximum width for larger screens */
    margin: 0 auto;
    background-color: #282c34; /* Dark background color */
    color: #dcdfe4; /* Light text color */
    border: 2px solid #61dafb; /* Vibrant border color */
  }
  
  /* Toolbar styles */
  .toolbar {
    text-align: center;
    background-color: #61dafb; /* Vibrant background color */
    padding: 10px;
    display: flex;
    justify-content: space-around; /* Even spacing between buttons */
   
  }
  
  /* Buttons in the toolbar */
  .button-blue,
  .button-yellow,
  .button-red,
  .button-green {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px; /* Rounded edges for buttons */
    cursor: pointer;
    transition: background-color 0.3s; /* Add a smooth hover effect */
  }
  
  .button-yellow {
    background-color: #ffc107;
  }
  
  .button-red {
    background-color: #dc3545;
  }
  
  .button-green {
    background-color: #28a745;
  }
  
  /* Hover effect for buttons */
  .button-blue:hover,
  .button-yellow:hover,
  .button-red:hover,
  .button-green:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  